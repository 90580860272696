/* Show the first part of the full team information */
.full-information {
    display: none;
}

.full-information .right{
    float: right;
}

.full-information a {
    text-decoration: none;
}

.full-information.active {
    display: block;
    animation: dashboard-fadein 0.8s;
}

/* Show the first part of the full team information */

/* General Styling */
h1 {
    color: var(--jade);
    font-size: 1.5rem;
    font-weight: bolder;
    letter-spacing: -1px;
    margin-bottom: 3vh;
}

button {
    border: none;
    color: var(--startup-white);
    background: var(--boundless-blue);
    border-radius: 55px;
    padding: 5px 15px;
    font-weight: 400;
}

/* General Styling */

/* My Team Styling */

/* Alternating rows with different background colour */
.team-profile .container {
    background-color: #F4F4F4;
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
}

.team-profile .container:nth-of-type(even) {
    background-color: var(--startup-white);
}

.logo {
    text-align: center;
    margin-top: -3.5em;
    transition: .5s ease;
    position: relative;
}

.logo img {
    width: 10rem;
    height: auto;
    margin-bottom: -4rem;
    transition: .5s ease;
    border-radius: 5%;
    object-fit: cover;
}

.logo section {
    visibility: hidden;
    text-align: center;
    margin: 5rem 0 2rem 0;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;

    background: #262626;
    color: var(--startup-white);
    width: 15em;


    border-radius: 4px;
}

.logo:hover img {
    /*border-radius: 50%;*/
    filter: blur(2px);
}

.logo:hover section {
    opacity: 0.4;
    visibility: visible;
}

/* Alternating rows with different background colour */

/* Row Title */
.row-title {
    color: var(--boundless-blue);
    font-weight: bold;
}

/* Row Subtitle */
.row-subtitle {
    color: var(--boundless-blue);
    font-weight: bold;
    font-size: 0.9rem;
}

/* Row information */
.row-information {
    color: var(--boundless-blue);
    font-weight: normal;
    font-size: 0.9rem;
}

.student-information {
    margin: -1px 0 10px 5px;
    font-size: 0.6rem;
    font-weight: normal;
    color: var(--jade);
}

.student-information a {
    color: var(--jade);
}

/* Editing button */
.edit {
    padding-top: 7px;
    padding-right: 7px;
    position: sticky;
    float: right;
    margin-top: -0.8vh;
    display: none;
}

.col-sm:hover.editable .edit {
    display: block;
}

.editing {
    display: none;
}

/* Editing button */

/* My Team Styling */


/*  Task List Page */
.phase-container .phase-unavailable{
    display: none;
}

.phase-container.blurred .phase-unavailable{
    display: block;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;

    padding: 5px 30px;
    border-radius: 10px;
    background: var(--jade);
    position: absolute;
    cursor: not-allowed;
    z-index: 1;
    left: 24vw;
    margin-top: 8.2vh;
}

.phase-container.blurred:hover .phase-unavailable{
    visibility: visible;
    opacity: 1;
}

.phase {
    background: #F5F5F5;
    border-radius: 31px;
    padding: 30px;
    margin-bottom: 50px;
}

.phase-container.blurred .phase:hover{
    transition: all 0.3s linear;
    cursor: not-allowed;
    filter: blur(3px);
}

.phase h2 {
    color: var(--apple-green);
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 15px;
}

.phase .task {
    margin-bottom: 10px;
}

.phase .task .information {
    z-index: 2;
    background: var(--boundless-blue);
    padding: 7px 35px;
    border-radius: 31px;
    font-size: 1.1rem;
    color: white;
    font-weight: 500;
    box-shadow: 3px 3px 10px rgb(0 0 0 / 10%);
}

.phase .task .information.red {
    background: var(--empowered-yellow) !important;
}

.phase .task .information.orange {
    background: var(--key-lime) !important;
}

.phase .task .information .title {
    font-size: 1rem;
}

.phase .task .information .right {
    float: right;
}

/**
Don't show the arrow key if blurred
 */
.phase-container.blurred .phase .task .information .right {
    display: none!important;
}

.phase .task .information .right .due-date {
    font-size: 0.65rem;
    font-weight: 400;
    margin-right: 2rem;
    margin-top: -20px;
}

.phase .task .information .right .open {
    margin-top: 1px;
    border: none;
    padding: 0;
    background: inherit;
}

/* Apply the animation speed */
.phase .task .information .rotate {
    transition-duration: 800ms;
    transition-property: transform;
}

/* Rotate down animation and back up when finished */
.phase .task .information .rotate-down {
    transform: rotate(180deg);
}

.phase .task .description {
    max-height: 0;
    transform: scaleY(0);
    margin-bottom: -3em;

    background: var(--ice);
    padding: 30px;
    border-radius: 0 0 50px 50px;
    margin-left: 40px;
    margin-right: 40px;
    color: var(--immersive-blue-black);
    z-index: 1;

    transition: all 800ms ease-in-out;
    transform-origin: left top;

}

.phase .task .description.red {
    background: var(--key-lime) !important;
}

.phase .task .description.orange {
    background: var(--jade) !important;
}

.phase .task .description.active {
    margin-bottom: 0;
    max-height: 500px;
    transform: scaleY(1);
}

.phase .task .description.active:target {
    height: auto;
}

.phase .task .description p {
    white-space: pre-wrap;
}

.phase .task .description h3 {
    text-align: center;
    margin-bottom: 1.5rem !important;
}

.phase .task .description button {
    display: block;
    margin: 40px auto 0;
    border-radius: 21px;
    padding: 10px 65px;

    border: none;
    background: var(--apple-green);
    color: var(--immersive-blue-black);
    font-weight: 600;

    transition: all 0.8s ease;
}

/**
When the button is locked, show a different colour and block cursor
 */
.phase .task .description button.locked {
    cursor: not-allowed;
    padding: 10px 25px;
    background: var(--pale-yellow);
}


.phase .task .description.red button {
    background: var(--sustainable-green);
}

.phase .task .description button span {
    display: none;
}

.phase .task .description button:hover span {
    display: block;
}

/*  Task List Page */

/* Events List Page */
.phase .attended {
    margin-left: 80%;

    .attended-amount{
        display: inline-block;
        border: none;
        color: var(--startup-white);
        background: var(--boundless-blue);
        border-radius: 50px;
        padding: 10px 15px;
        font-weight: 400;
        text-align: center;
    }

    .sub-information{
        text-align: left;

        font-size: 0.6rem;
        line-height: 0.8rem;
        width: 100%;
    }
}

.phase .task{
    .information.event.red{
        background: var(--realworld-red) !important;
    }
    .description.event.red{
        background: var(--realworld-red) !important;
        filter: brightness(150%);
    }

    .information.event.green{
        background: var(--jade) !important;
    }
    .description.event.green{
        background: var(--jade) !important;
        filter: brightness(130%);

    }

    .information.event.yellow{
        background: var(--apple-green) !important;
    }
    .description.event.yellow{
        background: var(--apple-green) !important;
        filter: brightness(110%);
    }
}
/* Events List Page */

/* Team Overview and Task Overview Page */


/* Form Container */
.external-form h1 {
    margin-top: -25px;
    color: white;
    font-size: 1.5rem;
}

.external-form h1:before {
    content: '';
    z-index: -1;
    border-top: var(--apple-green) solid 70px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

/* Form Container */

/* Article Container */
.content .finished {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content .finished button {
    padding: 10px 100px;
}

.content .finished button.grey {
    filter: grayscale(100%);
    cursor: not-allowed;
    margin-right: 10px;
}

/* Article Container */


/* Video Playlist Page */
.video-tutorials .title {
    margin-left: 3vh;
    margin-bottom: 0.7vh;
}

.video-tutorials h2 {
    color: var(--immersive-blue-black);
    font-size: 1.5rem;
    font-weight: bolder;
    letter-spacing: -1px;
}

.video-tutorials .embed {
    margin-left: 3vh;
}

.video-tutorials .embed iframe {
    width: 30vw;
    height: 18vw;
}

.video-tutorials .playlist-content h3 {
    font-size: 1.5rem;
    color: var(--cerulean);
}

.video-tutorials .playlist-content li {
    font-size: 1rem;
    line-height: 2vw;
}

.video-tutorials .videos {
    max-height: 0;
    transform: scaleY(0);

    transition: all 800ms ease-in-out;
    transform-origin: left top;
}

.video-tutorials .videos.active {
    max-height: 100000px;
    transform: scaleY(1);
}

.video-tutorials .videos.active:target {
    height: auto;
}

.video-tutorials .videos .col {
    margin-top: 2vh;
}

.video-tutorials .videos .col h4 {
    width: 80%;
    margin-left: 3rem;
    font-size: 1rem;
}

/* Video Playlist Page */

/* Share Registry Styling */
.share-registry .full-information .right{
    margin-top: -6vh;
}

/* Form Results Page */
.results {
    background: #F5F5F5;
    border-radius: 31px;
    padding: 30px;
}

/* Form Results Page */

/* Slick Slider */
/**
Slick slider overwrite buttons button change
 */
.slick-prev:before, .slick-next:before {
    font-family: 'Montserrat', sans-serif;
}

.slick-prev:before {
    content: url("slider/left-arrow.svg") !important;
}

.slick-next:before {
    content: url("slider/right-arrow.svg") !important;
}

.slick-prev {
    left: -4vw !important;
}

.slick-slider {
    margin-right: 5vw;
    margin-left: 5vw;
    margin-bottom: 10vh;
    display: inline-block;
}

/**
Change the format of each individual element
 */

/**
Each "round box" where the team name is shown
 */
.slick-item {
    border-radius: 36px;
    z-index: 2;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

    padding: 10px;
    font-weight: 400;
    text-align: center;

    min-width: 150px;
}

/**
The current team being shown
 */
.slick-active {
    background: var(--key-lime);
    color: white;
    font-weight: 500;
    border-radius: 36px;
}

/**
Add spacing between each element "spaced out elements"
 */
.slick-slide {
    margin: 0 4px;
}

/* the parent */
.slick-list {
    margin: 0 -4px;
}

/**
Move the slick slider slightly downwards to align with the left and right buttons
 */
.slick-track {
    margin-top: 1.7vw !important;
    margin-bottom: 0.5vw !important;
}

/* Slick Slider */

@keyframes dashboard-fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 1024px) {
    /* My Team Page */
    main#full-width {
        padding: 30px;
    }

    /* Task List Page */
    main#full-width.tasks {
        padding: 10px;
    }

    .phase .task .description {
        padding: 20px;
        margin-bottom: -2vh;
    }

    .phase .task .information .right {
        float: none;
        display: block !important;
        font-size: 1rem;
    }

    .phase-container.blurred .phase-unavailable{
        left: 20vw;
        margin-top: 6.3vh;
    }
    /* Task List Page */
}

@media (max-width: 900px) {
    /* Video Tutorials Page */
    .video-tutorials .playlist-content li {
        line-height: unset;
        padding-bottom: unset;
    }

    .video-tutorials .playlist-content button {
        margin-bottom: 2rem;
    }

    .video-tutorials .embed {
        margin-left: unset;
    }

    .video-tutorials .videos .col h4 {
        margin-left: 1rem;
    }

    .video-tutorials .embed iframe {
        width: 16em;
        height: 9em;
    }

    /* Video Tutorials Page */
}

@media (max-width: 480px) {
    /* Task List Page */
    .phase-container.blurred .phase-unavailable{
        font-size: 12px;
        margin: 40px 40px;
        left: 0;
    }

    .phase {
        border-radius: 21px;
        padding: 10px;
    }

    .phase .task .information {
        padding: 0 20px;
        border-radius: 20px;
    }

    .phase .task .information .title {
        font-size: 0.9rem;
    }

    .phase .task .information .due-date {
        font-size: 0.7rem;
    }

    .phase .task .description {
        padding: 10px;
        border-radius: 0 0 50px 50px;
        margin-left: 20px;
        margin-right: 20px;
    }

    /* Task List Page */
    /* Video Tutorials Page */
    .video-tutorials .title {
        margin-left: unset;
        margin-bottom: 1.2vh;
    }

    .video-tutorials .title img {
        margin-top: 1.2vh;
    }

    .video-tutorials .embed iframe {
        margin-bottom: 1.2vh;
    }

    .video-tutorials .embed iframe {
        width: 70vw;
        height: auto;
    }

    /* Video Tutorials Page */
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 100% !important;
    }
}