/* Only show the first form if there are multiple pages */
form {
    display: none;
    animation: form-fadein 0.8s;
}

form:first-of-type {
    display: block;
}

/* Only show the first form if there are multiple pages */

/* Remove the a tag default styling */
form a, form a:hover, form a:focus, form a:active {
    text-decoration: none !important;
    color: inherit !important;
}

form label {
    display: block;
    color: #00A0AF;

    font-weight: 600;
    font-size: 0.9em;
    margin: 20px 5px 10px;
    line-height: 1;
    letter-spacing: 0.05em;
    z-index: 1;
}

input, textarea, select {
    display: block;

    width: 100%;
    border: 2px double #BCE0FD;
    padding: 9px 15px 9px 15px;
    margin-bottom: 3vh;
    background: #f4fafd;

    border-radius: 10px;
    box-sizing: border-box;

    font-family: inherit;
    font-size: inherit;
    font-weight: 500;

    line-height: inherit;
    transition: 0.5s ease;

    resize: none;
    outline: none;
}

form input[type='radio'] {
    display: inline-block;
    margin: 0 20px 0 0;
    width: auto;
    height: 100%;
    appearance: none;
    outline: none;
    cursor: pointer;
    padding: 9px 35px 9px 35px;
    color: #00A0AF;
}

form input[type='radio']:checked {
    background-image: linear-gradient(90deg, #228F5B, #00B05C);
    color: #fff;
    box-shadow: 0 1px 1px #0000002e;
    text-shadow: 0 1px 0 #79485f7a;
}

form input[type='radio']:before {
    content: attr(data-label);
    display: inline-block;
    text-align: center;
    width: 100%;
}

form input[type="submit"], form button[type="submit"] {
    margin-top: 16vh;

    background: var(--boundless-blue);
    color: white;
    text-align: center;
    font-weight: 600;

    border: 0;
    border-radius: 21px;
    padding: 12px 10px !important;

    width: 10rem;
    float: right;
}

#submit-repeat{
    width: 100%;
    margin-top: 6vh;
    background: var(--aqua);
}

form button[type="submit"]:nth-child(2n){
    width: 100%;
    margin-top: 1vh;
}

button .loading {
    display: none;
}

button .loading.active {
    display: inline-block;
}

form span#forgot {
    margin-top: -2vh;
    margin-left: -0.3vw;
    background: white;
    font-size: 0.8em;

    outline: none;
    border: none;
    color: #00A0AF;
}

form .error-field {
    margin-top: -50px;
    color: red;
    font-size: 15px;
}

/* Class for when there is something wrong with a field */
form .error {
    border-color: red !important;
    position: relative;
    animation: shake 100ms linear;
    animation-iteration-count: 3;
}

/* Shake animation when something is wrong */
@keyframes shake {
    0% {
        left: -3px;
    }
    100% {
        right: -3px;
    }
}

main .container input {
    padding: 5px 12px;
}

/* Header of split box form */
main#split-box form h1 {
    margin-bottom: 5vh;
}

main#split-box .spacer {
    margin-bottom: 4rem;
}

/* Change the size of the input boxes */
#split-box form select, #split-box form input[type=number], #split-box form input[type=text], #split-box form textarea {
    width: 70%;
}

#split-box form input[type=submit], #split-box form button[type=submit] {
    position: absolute;
    margin: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    background: #FDB616;
    border: 0;
    border-radius: 31px 0 31px 0;
    padding: 12px 31px;
    line-height: inherit;
    cursor: pointer;

    font-size: 26px;
    font-weight: 500;
}

#split-box form input[type="submit"][value="Previous"] {
    position: absolute;
    left: 0;

    border-radius: 0 31px 0 31px;
}

@keyframes form-fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    form {
        text-align: left;
    }

    #split-box form select, #split-box form input[type=number], #split-box form input[type=text], #split-box form textarea {
        width: 100%;
    }

    main#split-box form h1 {
        margin-bottom: 7vh;
    }
}