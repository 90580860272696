/* Notification Styling */

/* Where to show the notification area (whole container) */
#notification-area {
    z-index: 2;
    position: absolute;
    right: 5rem;
    color: white;
    font-size: 1.6rem;
    margin-left: 4vw;
    margin-top: 3.2vh;
}

#notification-area.hidden {
    display: none;
}

#notification-button {
    border: 3px solid var(--empowered-yellow);
    color: var(--empowered-yellow);
    background: white;
    position: absolute;
    right: 2vw;
    font-size: 1.4rem;
}

#notification-button.unread {
    border: 3px solid var(--sustainable-green);
    color: var(--sustainable-green);
}

/* The box where the notification is will show */
#notification-box {
    display: none;

    background: white;
    margin-top: 4vh;

    border-radius: 31px;
    padding: 50px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
}

#notification-box.active {
    display: block;
    animation: notification-fadein 200ms linear 1 forwards;
}

#notification-box hr {
    color: black;
}

.notification-area {
    margin-bottom: 3vh;
}

.notification-area:last-child {
    margin-bottom: 0;
}

.notification-area h3 {
    font-size: 0.8rem;
}

.notification-area p {
    font-size: 0.6rem;
    margin-bottom: 0.2rem;
}

.notification-area .unseen {
    color: #00763D;
}

.notification-area .unseen.urgent {
    color: #F16002;
}

.notification-area .seen {
    color: #626366;
}

.notification-area a {
    text-decoration: none;
}

.notification-area a:hover {
    text-decoration: underline;
}

.notification-title{
}

.start-date{
    color: var(--pale-blue);
    margin-top: 15px;
    float: right;
    font-size: 0.8rem;
}

@keyframes notification-fadein {
    0% {
        opacity: 0;
        display: block
    }
    100% {
        opacity: 1;
    }
}

/* Notification Styling */
