@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --boundless-blue: #285F74;
    --resilient-turquoise: #00A0AF;
    --startup-white: #FFFFFF;
    --empowered-yellow: #E3E24F;
    --sustainable-green: #00763D;
    --immersive-blue-black: #22404D;
    --ice: #C3EDEF;
    --pale-blue: #99D9DF;
    --aqua: #00C0CA;
    --teal-blue: #008B9C;
    --cerulean: #28708B;
    --pale-yellow: #F3F2B3;
    --apple-green: #BBD153;
    --key-lime: #8FC440;
    --jade: #32b472;
    --grass-green: #009424;
    --realworld-red: #F65F4E
}

* {
    font-family: 'Montserrat', sans-serif;
}

/* General background */
body {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-image: url("background.png");
}

/* Some general styling */
button.grey:hover {
    filter: grayscale(100%);
    cursor: not-allowed;
}

/* Some general styling */

/* Resizable full screen box */
main:not(main.main-content) {
    position: absolute;
    border-radius: 31px;
    padding: 50px;
    background: white;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    overflow: hidden;
    left: 16rem;
    top: 10%;
    z-index: 1;
    word-wrap: break-word;

    animation: main-fadein 0.8s;
}

/* Full Width Style */
main#full-width {
    width: calc(100% - 20rem);
}

/* Small Form Style */
main#small-box {
    width: 500px;
    left: calc(50% - 10rem);
}

/* The header element of the small box */
main#small-box h1 {
    font-size: 2rem;
    font-weight: 500;

    padding: 20px 0 50px 0;
    color: #00A0AF;
}

main#small-box h2 {
    font-size: 0.8rem;
    font-weight: 400;
    margin: -50px 0 50px 0;

    color: #00A0AF;
}

/* The image element of the small box */
main#small-box img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 8rem;
}

/* Big Form Style */
main#split-box {
    width: 800px;
    left: calc(50% - 19rem);
}

main#split-box h1 {
    padding-top: 20px;
    color: #00A0AF;
    letter-spacing: 0;
    font-size: 22px;
    font-weight: 500;
}

main#split-box #left {
    z-index: 13;
    float: left;
    width: 60%;
}

main#split-box #right {
    z-index: 14;
    float: left;
    width: 40%;
}

main#split-box #right img {
    position: absolute;
    width: 35%;
    top: 30%;
    right: 40px;
    animation: main-fadein 0.8s;
}

main#full-width-bottom {
    display: none;
    position: fixed !important;
    width: calc(100% - 20rem);
    padding: 30px !important;

    bottom: 0 !important;
    top: unset !important;
    border-radius: 31px 31px 0 0 !important;
}

main#full-width-bottom .container {
    background-color: unset;
}

main#full-width-bottom.active {
    display: block;
}

main#full-width-bottom button {
    align-items: center;
}

main#full-width-bottom button[id="save"] {
    margin-right: 5rem;
    background: var(--boundless-blue);
}

main#full-width-bottom button[id="cancel"] {
    margin-left: 5rem;
    background: var(--empowered-yellow);
}


/* Have something on the right edge border of the page */
main .edge {
    color: #00763D;
    font-size: 0.8rem;
    margin-top: -5.8vh;
    margin-bottom: 6vh;
    text-align: right;
}

/* Border for the forms */
main.right-blue-border {
    border-right: var(--resilient-turquoise) solid 70px;
}

main.right-orange-border {
    border-right: #F06122 solid 70px;
}

/* Border for the forms */

@keyframes main-fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 1024px) {
    main {
        padding: 40px;
        left: 1rem !important;
        width: calc(100% - 2rem) !important;
    }

    main#split-box #left {
        width: 100%;
        margin-bottom: 5rem;
    }

    main#split-box #right {
        display: none;
    }

    main.right-blue-border {
        border-right: #1DB46C solid 8vw;
    }

    main.right-orange-border {
        border-right: #F06122 solid 8vw;
    }
}