/* Make the table scrollable */
table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

/* Make the head green with the text white */
table thead, table thead tr {
    background: var(--immersive-blue-black);
    color: white;
    border-radius: 36px;
}

/* Make the even color of each column grey */
table tbody tr:nth-of-type(even) {
    background: #F4F4F4;
}

/* Fix some padding in the header */
table thead tr th {
    padding: 0.75rem !important;
}

/* Fix the styling of the data within the table */
table tbody tr td {
    font-weight: 500;
    text-align: justify;
    padding-right: 4rem;
}

/* Green checkbox inside the table */
table .checkbox {
    color: var(--immersive-blue-black);
}

/* Extra information in the table */
table .extra-information {
    font-size: 0.7rem;
    font-weight: 400;
}

/* Remove any border from the table */
table .table-no-border {
    border: 0 !important;
}

/* Make the border slightly round */
table .table-no-border:first-child {
    border-radius: 36px 0 0 36px;
}

table .table-no-border {
    padding: .75rem;
    vertical-align: center;
    border-top: 1px solid #dee2e6;
}

/* Make the border slightly round */
table .table-no-border:last-child {
    border-radius: 0 36px 36px 0;
}

.download-button {
    margin-top: -5vh;
    display: inline;
    float: right;
    font-weight: 500;
    color: var(--immersive-blue-black);
    background-color: var(--empowered-yellow);
}
/* Team Overview and Task Overview Page */