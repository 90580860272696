/* Navigation bar styling */
nav {
    position: fixed;
    position: -webkit-sticky;
    float: left;
    width: 12.5rem;
    height: 100vh;
    z-index: 2;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    background: var(--resilient-turquoise);
    transition: all 0.4s;
    top: 0;
}

/* Top JA Banner Container */
nav #logo-banner-container {
    padding-top: 0;
    margin-top: 2vh;
    margin-bottom: -3.4vh;
}

nav #logo-banner-container div {
    transition: all 200ms ease-in-out;
}

nav #logo-banner-container div:hover {
    transition: all 200ms ease-in-out;
    margin-left: -5px;
}

nav #logo-banner-container div a img:nth-child(1) {
    margin-left: 0.5rem;
}

nav #logo-banner-container div svg {
    float: right;
    color: var(--ice);
    font-size: 0.6em;
    padding: 0.9rem 0 0 5px;
}

nav #logo-banner-container hr {
    margin-top: 3vh;
    border-top: 2px solid var(--startup-white);
    opacity: 100%;
}

/* Top JA Banner Container */

/* User Information Container */
nav #user-information-container div span {
    margin-bottom: 0;
    line-height: 1.2em;
    color: white;
}

nav #user-information-container div span.bold {
    font-weight: bold;
}

/* User Information Container */

/* Navigation Section */
nav #main-navigation {
    font-weight: 700;
}

nav #main-navigation li a {
    text-decoration: none;
    display: block;
    padding: 0.5rem 1rem;
    color: white;
}

nav #main-navigation li a i {
    margin-right: 1rem !important;
}

nav #logout-button {
    color: white;
    font-weight: 700;
    position: absolute;
    display: inline-block;
    bottom: 0;
}

nav #logout-button a {
    text-decoration: none;
}

#navigation-button {
    position: absolute;
    display: none;
    color: white;
    font-size: 1.6rem;
    margin-left: 4vw;
    margin-top: 4vh;
}

#navigation-button button {
    background: none;
}

@media (max-width: 1024px) {
    nav {
        margin-left: -12.7rem;
    }

    nav.active {
        margin-left: 0;
    }

    main {
        margin-left: 0;
    }

    #navigation-button {
        display: block;
    }

    nav.active ~ #navigation-button {
        margin-left: 13.7rem;
    }
}

/* Navigation Bar Styling */