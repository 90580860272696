body,
html {
    height: 100%;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

/* Primary color for the background */
.gjs-one-bg {
    background-color: var(--immersive-blue-black);
}

/* Secondary color for the text color */
.gjs-two-color {
    color: var(--startup-white)
}

/* Tertiary color for the background */
.gjs-three-bg {
    background-color: #BBD153;
    color: white;
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
    color: #46B17B;
}

input, textarea, select {
    margin-bottom: unset;
}

.gjs-field input, .gjs-field select, .gjs-field textarea {
    padding: 3px;
}